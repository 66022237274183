<template>
<!-- <div class="col-12 text-center">
  <img alt="logo" src="./assets/logo2.svg" style="width: 250px;">
</div> -->
  
  <router-view />

  <div class="row pt-3 d-flex justify-content-center">
    <div class="col-12 col-lg-6 text-center footer2 mb-2">
       <img alt="logo" src="./assets/poweredby-01.svg" style="width: 50%;">
    </div>
  </div>
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body{
 /* background-color: #F6785F !important;*/
  background-color: white !important;
   overflow-x: hidden;
}

.footer{
  background-color: black;
    color: white;
    line-height: 1.79em;
    font-size: 14px;
}
</style>
