import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
//import VueMoment from 'vue-moment'
//import moment from 'moment-timezone'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { createI18n } from 'vue-i18n'

// import translations
import es from "./locales/es.json";

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { es },
})


dom.watch();
library.add(fas, far, fab)


const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    timeout: 60000
  });

createApp(App)
.use(router)
.use(VueAxios, axiosInstance)
.use(i18n)
//.use(VueMoment)
.mount('#app')
