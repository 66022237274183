import { createWebHistory, createRouter } from "vue-router";
import Register from '../views/Register.vue';
//import {useStore} from 'vuex'
//import { entityStore } from '../store/store.js'



const routes = [
  {
    path: '/',
    name: 'Defaulth',
    component: Register,
  },
    {
      path: '/:key',
      name: 'Register',
      component: Register,
    },
   {
      path: '/:lang/:key',
      name: 'Register2',
      component: Register,
    },
     {
      path: '/:lang/:key/:contact',
      name: 'Register3',
      component: Register,
    },
    {
     path: '/:key/tag/:tag',
     name: 'Register4',
     component: Register,
   },
    {
     path: '/:lang/:key/tag/:tag',
     name: 'Register5',
     component: Register,
   },
   {
    path: '/:key/t/:tag',
    name: 'Register6',
    component: Register,
  },
   {
    path: '/:lang/:key/t/:tag',
    name: 'Register7',
    component: Register,
  }
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  

  /*router.beforeEach((to, from, next) => {
    const { key } = to.params;
   // i18n.locale = lang ? lang : 'en';

   //store.key = key;
   //store.addKey(key);
   console.log("Key1 " + key);

   const store = entityStore();
   //store.$state.key = key;
   store.addKey(key);
   console.log("Key2 " + store.$state.key);
    next();
  })*/
  
  export default router;