<style scoped>
.cr-rd-15 {
  border-radius: 15px !important;
}

.custom-date {
  background-color: white !important;
}

.v3dp__clearable {
  display: inline;
  position: absolute !important;
  left: 103px !important;
  top: 6px;
  cursor: pointer !important;
}

.v3dp__datepicker {
  flex: 1 1 auto;
}

input.custom-date.form-control {
  background-color: #ffffff !important;
}

.btn-bg {
  background-color: #324b82 !important;
}

.fs-20 {
  font-size: 20px;
}

.cr-rd-r-6 {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

/*tag input style*/

.tag-input {
  width: 50%;
  border: 1px solid #D9DFE7;
  background: #fff;
  border-radius: 4px;
  /*font-size: 0.9em;*/
  min-height: 45px;
  box-sizing: border-box;
  padding: 0 10px;
  /*font-family: "Roboto";*/
  /* margin-bottom: 10px; */
  display: inline-table;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #667EEA;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
  font-family: "Roboto";
}

.tag-input__tag>span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}
</style>
<template>
  <div v-if="!isLoaded" class="" style="font-size: 86px; padding-top: 240px; margin-bottom: 20%;">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>

  <div v-if="contact.tag && isLoaded" class="d-flex justify-content-center mb-2">
    <div class="col-12 text-uppercase font-weight-bold">
      {{ contact.tag }}
    </div>
  </div>

  <div v-if="isLoaded" class="col-12 text-center">
    <img v-if="dataLogoUrl" alt="logo" :src="dataLogoUrl" style="width: 250px" />
    <img v-if="!dataLogoUrl" alt="logo" src="../assets/logo2.svg" style="width: 250px" />
  </div>

  <div class="d-flex justify-content-center">
    <div v-if="entity.name" class="col-12 col-lg-6">
      <div class="b-overlay-wrap position-relative">
        <div class="text-uppercase fs-20 font-weight-bold">
          {{ entity.name }}
        </div>

        <div class="pb-2">{{ contact.id ? $t('UPDATE CONTACT REGISTRATION') : $t('NEW CONTACT REGISTRATION') }}</div>

        <div v-if="lang == 'EN' && entity.description || lang == 'ES' && entity.descriptionES" class="pb-2"
          v-html="lang == 'EN' ? entity.description : entity.descriptionES"></div>

        <div class="row bg-white shadow border cr-rd-15 m-0 p-3">
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-user fa-fw"></i></span>
            </div>
            <input type="text" :placeholder="$t('First Name')" :class="[
              'form-control pp-br-1 cr-rd-r-6',
              ,
              v$.contact.firstName.$error ? 'border-danger' : null,
            ]" v-model.trim="contact.firstName" />
            <span class="ml-2 mt-2 text-danger">*</span>
          </div>
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-user fa-fw"></i></span>
            </div>
            <input type="text" :placeholder="$t('Last Name')" :class="[
              'form-control pp-br-1 cr-rd-r-6',
              v$.contact.lastName.$error ? 'border-danger' : null,
            ]" v-model.trim="contact.lastName" />
            <span class="ml-2 mt-2 text-danger">*</span>
          </div>
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-transgender-alt fa-fw"></i></span>
            </div>
            <select :class="['form-control form-control-sm']" v-model="contact.gender">
              <option value="">{{ $t('Gender') }}</option>
              <option value="M">{{ $t('Male') }}</option>
              <option value="F">{{ $t('Female') }}</option>
              <option value="x">{{ $t('Other') }}</option>
            </select>
          </div>
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-birthday-cake fa-fw"></i></span>
            </div>
            <select :class="['form-control form-control-sm',
              v$.birth.year.$error ? 'border-danger' : null,]" v-model="birth.year">
              <option value="">{{ $t('Year') }}</option>
              <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
            </select>
            <select :class="['form-control form-control-sm',
              v$.birth.month.$error ? 'border-danger' : null,]" v-model="birth.month">
              <option value="">{{ $t('Month') }}</option>
              <option v-for="(month, index) in months" :value="index" :key="index">{{ $t(month) }}</option>
            </select>
            <select :class="['form-control form-control-sm',
              v$.birth.day.$error ? 'border-danger' : null,]" v-model="birth.day">
              <option value="">{{ $t('Day') }}</option>
              <option v-for="(day, index) in days" :value="day" :key="index">{{ day }}</option>
            </select>
          </div>
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-language fa-fw"></i></span>
            </div>
            <select :class="['form-control form-control-sm',
              v$.contact.lang.$error ? 'border-danger' : null,
            ]" v-model="contact.lang">
              <option value="">{{ $t('Language') }}</option>
              <option value="ES">{{ $t('Spanish') }}</option>
              <option value="EN">{{ $t('English') }}</option>
            </select>
          </div>
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-mobile-alt fa-fw"></i></span>
            </div>
            <input type="text" :placeholder="$t('Phone') + ' (0123456789)'" :class="[
              'form-control pp-br-1',
              v$.contact.phone.$error ? 'border-danger' : null,
            ]" v-model.trim="contact.phone" />
          </div>
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-at fa-fw"></i></span>
            </div>
            <input type="text" :placeholder="$t('Email')" :class="[
              'form-control pp-br-1',
              v$.contact.email.$error ? 'border-danger' : null,
            ]" v-model.trim="contact.email" />
          </div>
        </div>
        <div class="row bg-white shadow border cr-rd-15 m-0 p-3 mt-2">
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-map-marked-alt fa-fw"></i></span>
            </div>
            <input type="text" :placeholder="$t('Address Line') + ' 1'" class="form-control pp-br-1"
              v-model="contact.addressLine1" />
          </div>
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-map-marked-alt fa-fw"></i></span>
            </div>
            <input type="text" :placeholder="$t('Address Line') + ' 2'" class="form-control pp-br-1"
              v-model="contact.addressLine2" />
          </div>
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-city fa-fw"></i></span>
            </div>
            <input type="text" :placeholder="$t('City')" class="form-control pp-br-1" v-model="contact.city" />
          </div>

          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-map fa-fw"></i></span>
            </div>
            <select class="form-control form-control-sm" v-model="contact.state">
              <option value="">{{ $t('State') }}</option>
              <option v-for="s in states" :key="s.id" :value="s.id">
                {{ s.name }}
              </option>
            </select>
          </div>
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-globe-americas fa-fw"></i></span>
            </div>
            <select class="form-control form-control-sm" v-model="contact.country">
              <option value="">{{ $t('Country') }}</option>
              <option value="US">{{ $t('United States') }}</option>
              <option value="PR">{{ $t('Puerto Rico') }}</option>
            </select>
          </div>
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-map-pin fa-fw"></i></span>
            </div>
            <input type="text" :placeholder="$t('Zip Code')" class="form-control pp-br-1" v-model="contact.zip" />
          </div>
        </div>
        <div class="row bg-white shadow border cr-rd-15 m-0 p-3 mt-2">
          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-comment fa-fw"></i></span>
            </div>
            <textarea type="text" :placeholder="$t('Note')" class="form-control pp-br-1" v-model="contact.description" />
          </div>

          <div class="col-12 p-0 input-group shadow-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text cr-fc-1 bg-white"><i class="fas fa-hashtag fa-fw"></i></span>
            </div>
            <div class='tag-input form-control pp-br-1 text-left'>
              <div v-for='(tag, index) in contact.customTags' :key='tag' class='tag-input__tag'>
                {{ tag }}
                <span @click='removeTag(index)'>x</span>
              </div>
              <input type='text' placeholder="Enter a Tag" class='tag-input__text' @keydown.enter='addTag'
                @keydown.space='addTag' @blur="addTag" @keydown.delete='removeLastTag' />
            </div>
          </div>
        </div>
        <div class="col-12 p-0 text-center mb-4 mt-4">
          <button v-if="!isLoading" type="button" class="btn shadow-sm btn-bg cr-fw-500 text-white" @click="submit()">
            <i class="fas mr-2 fa-paper-plane"></i>
            {{ $t('Submit') }}
          </button>

          <button v-if="isLoading" type="button" class="btn shadow-sm btn-bg cr-fw-500 text-white">
            <i :class="['fas  fa-circle-notch fa-spin']"></i>
            {{ $t('Submit') }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="!entity.name && isLoaded" class="col-12 col-lg-6">
      <div class="
          row
          bg-white
          shadow
          border
          cr-rd-15
          m-0
          p-3
          d-flex
          justify-content-center
        ">
        <div class="col-12">INVALID URL</div>
      </div>
    </div>
  </div>
</template>

<script>
//import Datepicker from "vue3-datepicker";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf,
  email,
  numeric,
  minLength,
  maxLength,
  minValue,
  maxValue
} from "@vuelidate/validators";

import moment from 'moment';
//import translations from '/src/locales/translations.json'

export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name.trim())) {
    return true;
  }
  return false;
}

/*const phone = helpers.regex(
  "serial",
  /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
)*/

export default {
  name: "Register",
  data() {
    return {
      v$: useVuelidate(),
      isLoading: false,
      isLoaded: false,
      lang: "EN",
      entity: {
        name: "",
        description: "",
      },
      contact: {
        entity: "",
        id: "",
        firstName: "",
        lastName: "",
        gender: "",
        birthdate: null,
        lang: "",
        phone: "",
        email: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        description: "",
        logo: null,
        tag: "",
        customTags: []
      },
      birth: {
        year: "",
        month: "",
        day: ""
      },
      states: [
        {
          name: "Alabama",
          id: "AL",
        },
        {
          name: "Alaska",
          id: "AK",
        },
        {
          name: "American Samoa",
          id: "AS",
        },
        {
          name: "Arizona",
          id: "AZ",
        },
        {
          name: "Arkansas",
          id: "AR",
        },
        {
          name: "California",
          id: "CA",
        },
        {
          name: "Colorado",
          id: "CO",
        },
        {
          name: "Connecticut",
          id: "CT",
        },
        {
          name: "Delaware",
          id: "DE",
        },
        {
          name: "District Of Columbia",
          id: "DC",
        },
        {
          name: "Federated States Of Micronesia",
          id: "FM",
        },
        {
          name: "Florida",
          id: "FL",
        },
        {
          name: "Georgia",
          id: "GA",
        },
        {
          name: "Guam",
          id: "GU",
        },
        {
          name: "Hawaii",
          id: "HI",
        },
        {
          name: "Idaho",
          id: "ID",
        },
        {
          name: "Illinois",
          id: "IL",
        },
        {
          name: "Indiana",
          id: "IN",
        },
        {
          name: "Iowa",
          id: "IA",
        },
        {
          name: "Kansas",
          id: "KS",
        },
        {
          name: "Kentucky",
          id: "KY",
        },
        {
          name: "Louisiana",
          id: "LA",
        },
        {
          name: "Maine",
          id: "ME",
        },
        {
          name: "Marshall Islands",
          id: "MH",
        },
        {
          name: "Maryland",
          id: "MD",
        },
        {
          name: "Massachusetts",
          id: "MA",
        },
        {
          name: "Michigan",
          id: "MI",
        },
        {
          name: "Minnesota",
          id: "MN",
        },
        {
          name: "Mississippi",
          id: "MS",
        },
        {
          name: "Missouri",
          id: "MO",
        },
        {
          name: "Montana",
          id: "MT",
        },
        {
          name: "Nebraska",
          id: "NE",
        },
        {
          name: "Nevada",
          id: "NV",
        },
        {
          name: "New Hampshire",
          id: "NH",
        },
        {
          name: "New Jersey",
          id: "NJ",
        },
        {
          name: "New Mexico",
          id: "NM",
        },
        {
          name: "New York",
          id: "NY",
        },
        {
          name: "North Carolina",
          id: "NC",
        },
        {
          name: "North Dakota",
          id: "ND",
        },
        {
          name: "Northern Mariana Islands",
          id: "MP",
        },
        {
          name: "Ohio",
          id: "OH",
        },
        {
          name: "Oklahoma",
          id: "OK",
        },
        {
          name: "Oregon",
          id: "OR",
        },
        {
          name: "Palau",
          id: "PW",
        },
        {
          name: "Pennsylvania",
          id: "PA",
        },
        {
          name: "Puerto Rico",
          id: "PR",
        },
        {
          name: "Rhode Island",
          id: "RI",
        },
        {
          name: "South Carolina",
          id: "SC",
        },
        {
          name: "South Dakota",
          id: "SD",
        },
        {
          name: "Tennessee",
          id: "TN",
        },
        {
          name: "Texas",
          id: "TX",
        },
        {
          name: "Utah",
          id: "UT",
        },
        {
          name: "Vermont",
          id: "VT",
        },
        {
          name: "Virgin Islands",
          id: "VI",
        },
        {
          name: "Virginia",
          id: "VA",
        },
        {
          name: "Washington",
          id: "WA",
        },
        {
          name: "West Virginia",
          id: "WV",
        },
        {
          name: "Wisconsin",
          id: "WI",
        },
        {
          name: "Wyoming",
          id: "WY",
        },
      ],
    };
  },
  components: {
    //Datepicker,
  },
  validations: {
    contact: {
      firstName: {
        required,
        name_validation: {
          $validator: validName,
          $message:
            "Invalid Name. Valid name only contain letters, dashes (-) and spaces",
        },
      },
      lastName: {
        required,
        name_validation: {
          $validator: validName,
          $message:
            "Invalid Name. Valid name only contain letters, dashes (-) and spaces",
        },
      },
      email: {
        required: requiredIf(function () {
          return this.contact.phone === "";
        }),
        email,
      },
      phone: {
        required: requiredIf(function () {
          return this.contact.email === "";
        }),
        min: minLength(10),
        max: maxLength(25),
        numeric,
      },
      lang: { required },
    },
    birth: {
      year: {
        numeric,
        required: requiredIf(function () {
          return this.birth.month != "" || this.birth.day != "";
        })
      },
      month: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(11),
        required: requiredIf(function () {
          return this.birth.year !== '' || this.birth.day != "";
        })
      },
      day: {
        numeric,
        minValue: minValue(1),
        maxValue: maxValue(31),
        required: requiredIf(function () {
          return this.birth.year !== '' || this.birth.month !== '';
        })
      }
    },
  },
  methods: {
    async getEntity() {
      const params = new FormData();
      params.append("entityKey", this.contact.entity);
      params.append("contactKey", this.contact.id);

      const { data } = await this.axios.post(
        "entity/register/cr/contact/entity",
        params
      );
      this.entity = data;
      this.lang = this.lang != "" ? this.lang : data ? data.lang : "";

      if (this.lang != "") {
        this.$i18n.locale = this.lang.toLowerCase();
      }

      //CONTACT
      this.contact.firstName = data.contactFirstName;
      this.contact.lastName = data.contactLastName;
      this.contact.gender = data.contactGender ? data.contactGender : "";
      this.contact.birthdate = data.contactBirthdate ? new Date(data.contactBirthdate) : null;

      if (this.contact.birthdate) {
        // BIRTH
        this.birth.month = moment(this.contact.birthdate).month();
        this.birth.year = moment(this.contact.birthdate).year();
        this.birth.day = moment(this.contact.birthdate).date();
      }

      this.contact.lang = data.contactLang ? data.contactLang : this.lang;
      this.contact.phone = data.contactPhone;
      this.contact.email = data.contactEmail;
      this.contact.addressLine1 = data.contactAddressLine1;
      this.contact.addressLine2 = data.contactAddressLine2;
      this.contact.city = data.contactCity;
      this.contact.state = data.contactState ? data.contactState : data.state;
      this.contact.country = data.contactCountry ? data.contactCountry : data.country;
      this.contact.zip = data.contactZip;

      this.isLoaded = true

    },
    async submit() {
      this.isLoading = true;

      this.v$.$validate();
      if (this.v$.contact.$error) {
        this.v$.contact.$touch();

        this.isLoading = false;

        return;
      }

      try {
        // SET FORM DATA
        const params = new FormData();
        this.contact.tag = this.contact.tag ? "OnlineRegistration," + this.contact.tag : "OnlineRegistration";
        if (this.contact.customTags) {
          this.contact.tag = this.contact.tag + "," + this.contact.customTags.join();
        }
        const json = JSON.stringify(this.contact);
        params.append("contactJson", json);
        // REGISTER CONTACT ASYNC
        await this.axios.post("entity/register/cr/contact/save", params);
        //
        this.isLoading = false;

        Swal.fire({
          title: "Call Response",
          text: this.$t("Thank you. We have received your information."),
          icon: "success",
        }).then(() => {

          if (this.contact.id) {
            window.location.reload();
          }
          this.setDefault();
        });
      } catch (error) {
        var message = "";

        if (
          error.response.data.includes("CONTACT_PHONE_EMAIL_ALREADY_EXISTS")
        ) {
          message = "Phone and Email already exists";
        }

        if (error.response.data.includes("CONTACT_PHONE_ALREADY_EXISTS")) {
          message = "Phone already exists";
        }

        if (error.response.data.includes("CONTACT_EMAIL_ALREADY_EXISTS")) {
          message = "Email already exists";
        }

        if (message) {
          Swal.fire({
            title: "Call Response",
            text: this.$t(message),
            icon: "warning",
            customClass: {
              confirmButton: "btn-bg",
            },
          });
        } else {
          Swal.fire({
            title: "Call Response",
            text: this.$t("AN ERROR HAS OCURRED"),
            icon: "error",
            customClass: {
              confirmButton: "btn-bg",
            },
          });

          if (this.$route.params.tag) {
            this.contact.tag = this.$route.params.tag;
          } else {
            this.contact.tag = "";
          }

        }

        console.log(error);
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
    addTag(event) {
      event.preventDefault()
      let val = event.target.value.trim()
      if (val.length > 0) {
        if (this.contact.customTags.length >= 1) {
          for (let i = 0; i < this.contact.customTags.length; i++) {
            if (this.contact.customTags[i] === val) {
              return false
            }
          }
        }
        this.contact.customTags.push(val)
        event.target.value = ''
        console.log(this.contact.customTags)
      }
    },
    removeTag(index) {
      this.contact.customTags.splice(index, 1)
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.contact.customTags.length - 1)
      }
    },
    setDefault() {
      this.contact.firstName = "";
      this.contact.lastName = "";
      this.contact.gender = "";
      this.contact.birthdate = null;
      //this.contact.lang = "";
      this.contact.phone = "";
      this.contact.email = "";
      this.contact.addressLine1 = "";
      this.contact.addressLine2 = "";
      this.contact.city = "";
      //this.contact.state = "";
      //this.contact.country = "";
      this.contact.zip = "";
      this.contact.description = "";

      if (this.$route.params.tag) {
        this.contact.tag = this.$route.params.tag;
      } else {
        this.contact.tag = "";
      }

    },

  },
  watch: {
    'birth': {
      handler: function (birth) {
        const birthdate = moment([birth.year, birth.month, birth.day]);
        if (birthdate.isValid()) {
          this.contact.birthdate = birthdate.format('YYYY-MM-DD');
        } else {
          this.contact.birthdate = null;
          this.birth.day = "";
        }
      },
      deep: true
    }
  },
  computed: {
    dataLogoUrl() {
      return this.entity.logo
        ? "data:image/png;base64," + this.entity.logo
        : null;
    },
    years() {
      let year = moment().year() - 120;
      var years = [];
      while (year <= moment().year()) {
        years.push(year);
        year++;
      }
      return years;
    },
    months() {
      return moment.monthsShort();
    },
    days() {
      const {
        year,
        month
      } = this.birth

      if (year === '' || month === '') {
        return;
      }

      return moment([year, month]).daysInMonth();
    }
  },
  mounted() {
    this.contact.entity = this.$route.params.lang?.toString().length == 2 || this.$route.params.lang == undefined ? this.$route.params.key.toString() : this.$route.params.lang.toString();

    if (this.$route.params.contact || this.$route.params.lang?.toString().length > 2) {
      this.contact.id = this.$route.params.lang?.toString().length > 2 ? this.$route.params.key.toString() : this.$route.params.contact.toString() != "" ? this.$route.params.contact.toString().toUpperCase() : "";
    }

    if (this.$route.params.tag) {
      this.contact.tag = this.$route.params.tag;
    }

    this.lang = this.$route.params.lang?.toString().length == 2 ? this.$route.params.lang.toString().toUpperCase() : "";

    if (this.lang != "") {
      this.$i18n.locale = this.lang.toLowerCase();
    }
    this.getEntity();
  },
};
</script>